.root {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #333;
  background-color: #fff;
}

.sliderWrapper,
.sliderItem {
  height: 100%;
}

.itemImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.arrow {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  background-color: #fff;
  cursor: pointer;

  /* border-radius: 50%; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--chakra-colors-gray-100);
}

.arrow svg {
  width: 25px;
  height: 25px;
  fill: var(--chakra-colors-gray-800);
}

.arrow:disabled {
  opacity: 0.3;
}

.arrow_left {
  left: 15px;
}

.arrow_right {
  left: auto;
  right: 15px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}
