.buttonLinkWrapper {
  background-color: pink;
}

.button{
  padding: 7px 15px;
  border-radius: 4px;
  display: inline-block;
}

.primary{
  background-color: var(--chakra-colors-primary);
  color: var(--chakra-colors-white);
}

.secondary{
  background-color: transparent;
  color: var(--chakra-colors-primary);
  border: 1px solid var(--chakra-colors-primary);
}

.default{
  background-color: transparent;
  color: var(--chakra-colors-primary);
}

.default:hover{
  text-decoration: underline;
}

.defaultColor{
  color: var(--chakra-colors-primary);
  border-color:var(--chakra-colors-primary);
}

.blackColor{
  color: var(--chakra-colors-black);
  border-color:var(--chakra-colors-black);
}

.whiteColor{
  color: var(--chakra-colors-white);
  border-color:var(--chakra-colors-white);
}

.primary:where(.blackColor){
  background-color: var(--chakra-colors-black);
  color: var(--chakra-colors-white);
}

.primary:where(.defaultColor){
  color: var(--chakra-colors-white);
}