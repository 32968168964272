.videoCoverWrapper {
  height:700px;
  overflow:hidden;
  background-color:black;
  position:relative;
}

.videoCoverWrapper::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
  z-index: 2;
  content: " ";
  opacity: 0.5;
}

.videoCoverWrapper iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 700px;
  transform: translate(-50%, -50%);
  pointer-events: none;
  overflow: hidden;
}

@media (min-aspect-ratio: 16/9) {
  .videoCoverWrapper iframe {
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .videoCoverWrapper iframe {
    width: 177.78vh;
  }
}

@media(max-width:768px){
  .videoCoverWrapper::after {
    display: none;
  }
  .videoCoverWrapper {
    height:300px;
  }
  .videoCoverWrapper iframe {
    pointer-events: unset;
  }
}