.root {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #333;
  background-color: #fff;
}

.image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.imageGridItem{
  position: relative;
}

.first_square_image,
.horizontal_image,
.second_square_image,
.third_square_image {
  max-height: 180px;
}

.big_image {
  max-height: 380px;
}
